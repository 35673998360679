import { Button, CalendarRange, Input, Layout, ListProps, ModalService, Text } from '@ui-kitten/components';
import moment from 'moment';
import React, { useCallback, useMemo, useRef } from 'react';
import { SaleInvoiceListViewModel } from '../../../model/sale-invoice.model';
import { AppAuthService } from '../../../services/app-auth.service';
import { AppFetch } from '../../../services/app-fetch.service';
import { AppRoute } from '../../../app/app-routes';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
// import { data } from './extra/data';
import { SaleInvoiceList } from './extra/sale-invoice-list.component';
import Toast from 'react-native-root-toast';
import { Pressable } from 'react-native';

ModalService.setShouldUseTopInsets = true

export interface SaleInvoiceListProps {
 range: CalendarRange<Date>;
 keyword: string;
 navigation: any;
}

export default (props: SaleInvoiceListProps): LayoutListElement => {
  const { navigation, range, keyword } = props;
  const [data, setData] = React.useState<SaleInvoiceListViewModel[]>([]);
  const loadData = () => {
    const {startDate, endDate} = range as CalendarRange<Date>;
    console.log(keyword)
    if(startDate && endDate) {
      const request = {
        keyword: keyword,
        startDate: moment(startDate).format('YYYY/MM/DD'),
        endDate: moment(endDate).format('YYYY/MM/DD'),
      };
      AppFetch.post(`SaleInvoice/GetAllSaleInvoice`, request).then(rp => {
        setData(rp);
      });
    }
  }
  React.useEffect(() => {
    loadData();
  }, [range, keyword]);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });

    return unsubscribe;
  }, [navigation]);

  const onItemPress = (index: number): void => {
    navigation.navigate(AppRoute.SALE_INVOICE_EDIT, { invoiceId: data[index].invoiceId });
  };

  let modalID = '';
  const onItemLongPress = (index: number): void => {
    // navigation.navigate(data[index].route);
    const contentElement = renderModalContentElement(index);
    modalID = ModalService.show(contentElement, { onBackdropPress: hideModal, backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } });
  };

  const hideModal = () => {
    ModalService.hide(modalID);
  };

  const renderModalContentElement = (index) => {
    return (
      <Pressable onPress={hideModal} style={{ flex: 1, justifyContent: 'flex-end' }}>
        <Layout style={{ flex: 1, justifyContent: 'flex-end', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <Button
            onPress={() => onPressTrangThai(index, 0)}
            appearance='outline'
            status='warning'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Chưa Duyệt
            </Button>
          <Button
            onPress={() => onPressTrangThai(index, 1)}
            appearance='outline'
            status='success'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Đã Duyệt
            </Button>
          <Button
            onPress={() => onPressTrangThai(index, 2)}
            appearance='outline'
            status='primary'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Đã Xuất Kho
            </Button>
          <Button
            onPress={() => onPressTrangThai(index, 3)}
            appearance='outline'
            status='info'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Đã Giao Hàng
            </Button>
          <Button
            onPress={() => onPressTrangThai(index, 4)}
            appearance='outline'
            status='basic'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Huỷ
            </Button>
        </Layout>
      </Pressable>
    );
  };

  const onPressTrangThai = async (index, trangThai) => {
    hideModal();
    const item = data[index];
    /**
         * Set Data
         */
    const request = [{ invoiceId: item.invoiceId, invoiceStatus: trangThai }];
    /**
     * Validate
     */
    // if (this.selected.length === 0) {
    //   this.translate.get('MESSAGE.COMMON.NOTSELECT').subscribe(text => {
    //     this.message.warning(text);
    //   });
    //   return;
    // }
    // const checkDiff = this.selected.map(x => x.trangThai).sort();
    // if (checkDiff[0] !== checkDiff[checkDiff.length - 1]) {
    //   this.translate.get('Các hóa đơn phải trùng trạng thái').subscribe(text => {
    //     this.message.warning(text);
    //   });
    //   return;
    // }
    console.log(trangThai, item.trangThai)
    if (trangThai > item.trangThai + 1 || trangThai < item.trangThai - 1) {
      Toast.show('Các hóa đơn phải đổi qua trạng thái kế tiếp');
      return;
    }
    const permissions = (await AppAuthService.getXbookUserInfo())?.permission;
    if (!permissions.some(x => x.functionId === 'Invoice' && x.update === true)) {
      if (trangThai === 0 && trangThai < item.trangThai) {
        Toast.show('Chỉ người kiểm duyệt mới được chuyển về "chưa duyệt"');
        return;
      }
      if (trangThai === 1 && trangThai > item.trangThai) {
        Toast.show('Chỉ người kiểm duyệt mới được duyệt đơn');
        return;
      }
    }

    /**
     * Request
     */
    AppFetch.patch(`SaleInvoice/UpdateInvoiceStatus`, request).then(rp => {
      Toast.show('Đã đổi trạng thái');
      loadData();
    });
    // this.invoiceService.updateInvoiceStatus(request).subscribe(() => {
    //   this.selected = [];
    //   this.notify.success('Thay đổi thành công');
    //   this.getInvoice(this.requesSearchtList);
    // });
  }

  return (
    <Layout level='1' style={{ flex: 1 }}>
      <SaleInvoiceList
        data={data}
        onItemPress={onItemPress}
        onItemLongPress={onItemLongPress}
      />
      
    </Layout>
  );
};