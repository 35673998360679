import React, { useCallback, useMemo, useRef } from 'react';
import { ChamCongItem } from 'src/model/cham-cong-item.model';
import { AppFetch } from '../../../services/app-fetch.service';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { ChamCongList } from './extra/cham-cong-list.component';
import { data } from './extra/data';
import { AppAuthService } from '../../../services/app-auth.service';
import { CalendarRange } from '@ui-kitten/components';
import moment from 'moment';

export default ({ navigation, range }): LayoutListElement => {
  const [data, setData] = React.useState<ChamCongItem[]>([]);
  const loadData = () => {
    AppAuthService.getEmail().then(email => {
      const {startDate, endDate} = range as CalendarRange<Date>;
      if(startDate && endDate) {
        AppFetch.get(`ChamCongXuong?maNhanVien=${email}&tuNgay=${moment(startDate).format('YYYY/MM/DD')}&denNgay=${moment(endDate).format('YYYY/MM/DD')}`).then(rp => {
          setData(rp.data);
        });
      }
    });
  }
  React.useEffect(() => {
    loadData();
  }, [range]);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });

    return unsubscribe;
  }, [navigation]);


  const onItemPress = (index: number): void => {
    // navigation.navigate(data[index].route);
  };

  const onItemLongPress = (index: number): void => {
    // navigation.navigate(data[index].route);
  };
  const onItemDeletePress = (id: number): void => {
    AppFetch.delete(`ChamCongXuong/${id}`).then(rp => {
      loadData();
    });
  };

  return (
    <ChamCongList
      data={data}
      onItemPress={onItemPress}
      onItemLongPress={onItemLongPress}
      onItemDeletePress={onItemDeletePress}
    />
  );
};