import { Alert, NativeModules } from 'react-native';
import { AuthorizeResult } from 'react-native-app-auth';
import { AppConst } from '../app/app-const';
import { AppAuthService } from './app-auth.service';
import { AppStorage } from './app-storage.service';

export class AppFetch {

  private static authHeader = async () => {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = await AppAuthService.getToken();
    const isLoggedIn = !!token;
    if (isLoggedIn) {
      return `Bearer ${token}`;
    } else {
      return '';
    }
  }
  private static getHeader: () => Promise<HeadersInit> = async () => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json'); 
    requestHeaders.set('Accept', 'application/json'); 
    requestHeaders.set('Authorization', await AppFetch.authHeader()); 

    return requestHeaders;
  }

  static get = async (url: string): Promise<any> => {
    let urlRequest = AppConst.BACKEND_URL + url;
    if(url.startsWith('http')){
      urlRequest = url;
    }
    return fetch(urlRequest, {
      method: 'GET',
      headers: await AppFetch.getHeader(),
    })
      .then(res => {
        return res.json()
      }).catch((err)=> {
        console.error(err);
        Alert.alert('Có lỗi xảy ra khi tải dữ liệu');
      })
  };
  static post = async (url: string, body): Promise<any> => {
    let urlRequest = AppConst.BACKEND_URL + url;
    if(url.startsWith('http')){
      urlRequest = url;
    }console.log(body);
    return fetch(urlRequest, {
      method: 'POST',
      headers: await AppFetch.getHeader(),
      body: JSON.stringify(body),
    })
      .then(res => {
        console.log(res);
        try {
          return res?.json();
        } catch(ex) {
          return null;
        }
      }).catch((err)=> {
        Alert.alert('Có lỗi xảy ra khi tải dữ liệu');
      })
  };
  static patch = async (url: string, body): Promise<any> => {
    let urlRequest = AppConst.BACKEND_URL + url;
    if(url.startsWith('http')){
      urlRequest = url;
    }
    return fetch(urlRequest, {
      method: 'PATCH',
      headers: await AppFetch.getHeader(),
      body: JSON.stringify(body),
    })
      .then(res => {
        try {
          return res?.json();
        } catch(ex) {
          return null;
        }
      }).catch((err)=> {
        Alert.alert('Có lỗi xảy ra khi tải dữ liệu');
      })
  };
  static delete = async (url: string): Promise<any> => {
    let urlRequest = AppConst.BACKEND_URL + url;
    if(url.startsWith('http')){
      urlRequest = url;
    }
    return fetch(urlRequest, {
      method: 'DELETE',
      headers: await AppFetch.getHeader()
    })
      .then(res => {
        return res.bodyUsed ? res?.json(): '';
      }).catch((err)=> {
        console.log(err);
        Alert.alert('Có lỗi xảy ra khi tải dữ liệu');
      })
  };
}
