import { Alert, NativeModules } from 'react-native';
import { AppConst } from '../app/app-const';
import { AppStorage } from './app-storage.service';
import { authorize, AuthorizeResult, prefetchConfiguration, refresh, revoke } from 'react-native-app-auth';
import { AppRoute } from '../app/app-routes';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { ResponseType } from 'expo-auth-session';
import { CommonActions } from '@react-navigation/native';
import Toast from 'react-native-root-toast';
import { AppFetch } from './app-fetch.service';

WebBrowser.maybeCompleteAuthSession();

const useProxy = false;

const redirectUri = AuthSession.makeRedirectUri({
  useProxy,
});
const requestInfo = {
  clientId: 'Xbook_pro_dong_tam_mobile_dev',
  redirectUri,
  shouldAutoExchangeCode: false,
  clientSecret: 'false',
  responseType: ResponseType.Code,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
};
const issuer = 'https://xbaccount.b24.vn';


export class AppAuthService {

  static getConfig = () => [requestInfo, issuer]

  static handleAuthorize = async (navigation, config) => {
    const discovery = await AuthSession.fetchDiscoveryAsync(issuer);
    const [request, result, promptAsync] = config;
    var response = await promptAsync({ useProxy });
    if (response && response?.type === 'success') {

      const auth = response.params;
      Toast.show('Đang lấy thông tin user');
      var token = await AuthSession.exchangeCodeAsync(
        {
          code: auth.code,
          redirectUri: redirectUri,
          clientId: requestInfo.clientId,
          extraParams: {
            code_verifier: request.codeVerifier,
          },
          ...requestInfo
        }, discovery);
      const userInfo = await AuthSession.fetchUserInfoAsync({ accessToken: token.accessToken }, discovery);
      
      AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify(auth));
      AppStorage.set(AppConst.USER_TOKEN, JSON.stringify(token));
      AppStorage.set(AppConst.USER_INFO, JSON.stringify(userInfo));
      const xbookUserInfo = await AppFetch.get('Account/GetUserInfo');
      AppStorage.set(AppConst.XBOOK_USER_INFO, JSON.stringify(xbookUserInfo));


      // navigation && navigation.navigate(AppRoute.HOME);
    }
  }
  static logout:() => Promise<any> = async () => {
    return AppAuthService.getIdToken().then(token => {
      return WebBrowser.openAuthSessionAsync(`https://xbaccount.b24.vn/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${redirectUri}`, redirectUri).then(browser => {
        WebBrowser.dismissAuthSession();
      });
    })
  }

  static isLoggedIn: () => Promise<boolean> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_IDENTITY_INFO);
    const token = data?.code;
    return !!token;
  }

  static getToken: () => Promise<string> = async () => {
    const data = await AppStorage.get<AuthSession.TokenResponse>(AppConst.USER_TOKEN);
    const token = data?.accessToken;
    return token;
  }
  static getIdToken: () => Promise<string> = async () => {
    const data = await AppStorage.get<AuthSession.TokenResponse>(AppConst.USER_TOKEN);
    const token = data?.idToken;
    return token;
  }

  static getName: () => Promise<string> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const token = data?.name;
    return token;
  }

  static getEmail: () => Promise<string> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const rs = data?.email;
    return rs;
  }

  static getUserInfo: () => Promise<any> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    return data;
  }

  static getXbookUserInfo: () => Promise<any> = async () => {
    const data = await AppStorage.get<any>(AppConst.XBOOK_USER_INFO);
    return data;
  }
}
