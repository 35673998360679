import React, { useCallback, useMemo, useRef } from 'react';
import { Card, CardElement, Layout, List, ListElement, ListProps, Text } from '@ui-kitten/components';
export const StatusLabel = ({ status }) => {
  let statusTheme = '';
  let text = '';
  switch (status) {
    case 0:
      statusTheme = 'warning';
      text = 'Chưa duyệt';
      break;
    case 1:
      statusTheme = 'success';
      text = 'Đã duyệt';
      break;
    case 2:
      statusTheme = 'primary';
      text = 'Đã xuất kho';
      break;
    case 3:
      statusTheme = 'info';
      text = 'Đã giao hàng';
      break;
    case 4:
      statusTheme = 'basic';
      text = 'Hủy';
      break;
  }
  return (<Text category='s2' appearance='hint' status={statusTheme}>{text}</Text>);
}