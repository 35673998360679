import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { ChamCongListScreen } from '../scenes/cham-cong/cham-cong-list.component';
import { AppRoute } from '../app/app-routes';
import { ChamCongCreateScreen } from '../scenes/cham-cong/cham-cong-create.component';

const TopTab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();


export const ChamCongNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode='none'>
    <Stack.Screen name={AppRoute.CHAM_CONG_LIST} component={ChamCongListScreen}/>
    <Stack.Screen name={AppRoute.CHAM_CONG_CREATE} component={ChamCongCreateScreen}/>
  </Stack.Navigator>
);
