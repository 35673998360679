import React, { useCallback, useMemo, useRef } from 'react';
import { ChamCongItem, ChamCongItemDetail } from 'src/model/cham-cong-item.model';
import { AppFetch } from '../../../services/app-fetch.service';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { DuyetChamCongList } from './extra/duyet-cham-cong-list.component';
import { data } from './extra/data';
import Toast from 'react-native-root-toast';
import { CalendarRange } from '@ui-kitten/components';
import moment from 'moment';

export default ({ navigation, range , keyword = ''  }): LayoutListElement => {
  const [data, setData] = React.useState<ChamCongItem[]>([]);
  const loadList = () => {
    const {startDate, endDate} = range as CalendarRange<Date>;
      if(startDate && endDate) {
        AppFetch.get(`ChamCongXuong?tuNgay=${moment(startDate).format('YYYY/MM/DD')}&denNgay=${moment(endDate).format('YYYY/MM/DD')}`).then(rp => {
          setData(rp.data.filter(x => x.tenNhanVien !== null && x.tenNhanVien.includes(keyword) ));
        });
      }
  }
  React.useEffect(() => {
    loadList();
  }, [range, keyword]);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadList();
    });

    return unsubscribe;
  }, [navigation]);
  

  const onDaDuyetChange = (value: boolean, item: ChamCongItemDetail): void => {
    // navigation.navigate(data[index].route);
    item.daDuyet = value;
    AppFetch.post(`ChamCongXuong/UpdateDaDuyet/${item.id}`, value).then(rs => {
      Toast.show('Đã lưu');
      loadList();
    });

    var tempData = [...data];
    
    setData(tempData);
    console.log(tempData, value)
  };

  const onItemLongPress = (index: number): void => {
    // navigation.navigate(data[index].route);
  };

  return (
    <DuyetChamCongList
      data={data}
      onDaDuyetChange={onDaDuyetChange}
      onItemLongPress={onItemLongPress}
    />
  );
};