import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { SaleInvoiceListScreen } from '../scenes/sale-invoice/sale-invoice-list.component';
import { AppRoute } from '../app/app-routes';
import { SaleInvoiceEditScreen } from '../scenes/sale-invoice/sale-invoice-edit.component';

const TopTab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();


export const SaleInvoiceNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode='none'>
    <Stack.Screen name={AppRoute.SALE_INVOICE_LIST} component={SaleInvoiceListScreen}/>
    <Stack.Screen name={AppRoute.SALE_INVOICE_EDIT} component={SaleInvoiceEditScreen}/>
  </Stack.Navigator>
);
