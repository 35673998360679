import React, { useCallback, useMemo, useRef } from 'react';
import { ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { Button, Card, CardElement, CheckBox, Icon, Layout, List, ListElement, ListProps, Text, Toggle } from '@ui-kitten/components';
import { ChamCongItem, ChamCongItemDetail } from '../../../../model/cham-cong-item.model';
import Animated, { Extrapolate, interpolate } from 'react-native-reanimated';

export interface ChamCongListProps extends Omit<ListProps, 'renderItem'> {
  data: ChamCongItem[];
  onItemPress: (index: number) => void;
  onItemLongPress?: (index: number) => void;
  onItemDeletePress?: (index: number) => void;
}

export type ChamCongListElement = React.ReactElement<ChamCongListProps>;

export const ChamCongList = (props: ChamCongListProps): ListElement => {
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  const TrashIcon = (props) => (
    <Icon {...props} name='trash-2'/>
  );

  const { contentContainerStyle, onItemPress, onItemLongPress, onItemDeletePress, ...listProps } = props;

  const renderItem = (info: ListRenderItemInfo<ChamCongItem>): CardElement => (
    <Card
      style={styles.itemContainer}
      onPress={() => onItemPress(info.index)}
      onLongPress={() => { onItemLongPress(info.index); }}>
      <Layout style={styles.layoutContainer}>

        <Layout style={{ ...styles.layout, flex: 11 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s1'>
            {info.item.ngayCC}
          </Text>
        </Layout>

        <Layout style={{ ...styles.layout, flex: 1, alignItems: 'center' }} level='1'>
          <Text
            category='s1'>
            {info.item.detail.filter(x => x.daDuyet).map(x => x.soGioCong).reduce((value, total) => value + total, 0)}
          </Text>
        </Layout>

      </Layout>
      {Array.from(groupBy(info.item.detail, item => item.projectName).values()).map((value: ChamCongItemDetail[]) => {
        return <Layout>
          <Text
            style={styles.itemDescription}
            category='s2'
            appearance='hint'>
            {value[0].projectName}
          </Text>
          {value.map(x => {
            return <Layout style={{
              flexDirection: 'row', marginTop: 3,
              marginLeft: 20
            }}>
              <Layout style={{ flex: 8, justifyContent: 'center' }}><Text category='s2' appearance='hint'>{x.tenThanhPham}</Text></Layout>
              <Layout style={{ flex: 3, justifyContent: 'center' }}><Text category='s2' appearance='hint'>Số giờ: {x.soGioCong} ({x.daDuyet ? 'Duyệt' : 'Chưa'})</Text></Layout>
              <Layout style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                {!x.daDuyet && <Button size='tiny' status='danger' appearance='ghost' accessoryLeft={TrashIcon} onPress={() => onItemDeletePress(x.id)}></Button>}
              </Layout>
            </Layout>
          })}
        </Layout>
      })}
    </Card>
  );

  return (
    <List
      {...listProps}
      contentContainerStyle={[styles.container, contentContainerStyle]}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingVertical: 4,
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 0,
  },
  itemDescription: {
    marginTop: 2,
  },
  secondaryText: {
  },
  layoutContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomSheetModal: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
  },
  bottomSheetModalItem: {
    flex: 1,
    width: '100%',
  },
  bottomSheetModalItemText: {
    textAlign: 'center'
  },
});
