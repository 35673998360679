import { Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import React from 'react';
import { ArrowIosBackIcon } from '../../components/icons';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import ContentView from '../../layouts/sale-invoice/sale-invoice-edit';

export const SaleInvoiceEditScreen = ({ navigation }): React.ReactElement => {

  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      icon={ArrowIosBackIcon}
      onPress={navigation.goBack}
    />
  );

  return (<SafeAreaLayout style={{ flexDirection: 'column', flex: 1 }}
  insets='top'>
  <TopNavigation
    title='Xem Bán hàng'
    accessoryLeft={renderBackAction}
  />
  <Divider />
  <ContentView navigation={navigation} />
</SafeAreaLayout>
);
}
