import React, { useCallback, useMemo, useRef } from 'react';
import { ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { Card, CardElement, CheckBox, Layout, List, ListElement, ListProps, Text, Toggle } from '@ui-kitten/components';
import { ChamCongItem, ChamCongItemDetail } from '../../../../model/cham-cong-item.model';
import Animated, { Extrapolate, interpolate } from 'react-native-reanimated';

export interface DuyetChamCongListProps extends Omit<ListProps, 'renderItem'> {
  data: ChamCongItem[];
  onDaDuyetChange: (value: boolean, item: ChamCongItemDetail) => void;
  onItemLongPress?: (index: number) => void;
}

export type ChamCongListElement = React.ReactElement<DuyetChamCongListProps>;

export const DuyetChamCongList = (props: DuyetChamCongListProps): ListElement => {
  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const { contentContainerStyle, onDaDuyetChange, onItemLongPress, ...listProps } = props;

  const renderItem = (info: ListRenderItemInfo<ChamCongItem>): CardElement => (
    <Card 
      key={info.index}
      style={styles.itemContainer}
      // onPress={() => onItemPress(info.index)}
      onLongPress={() => { onItemLongPress(info.index); }}>
      <Layout style={styles.layoutContainer}>

        <Layout style={{ ...styles.layout, flex: 5 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s1'>
            {info.item.tenNhanVien}
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 2 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s2'>
            {info.item.ngayCC}
          </Text>
        </Layout>

        <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s1'>
            {info.item.detail.filter(x => x.daDuyet).map(x => x?.soGioCong).reduce((value, total) => value + total, 0)}
          </Text>
        </Layout>

      </Layout>
      {Array.from(groupBy(info.item.detail, item => item.projectName).values()).map((value: ChamCongItemDetail[], index) => {
        return <Layout key={index}>
          <Text
            style={styles.itemDescription}
            category='s2'
            appearance='hint'>
            {value[0].projectName}
          </Text>
          {value.map((x, index) => {
            return <Layout 
              key={ index }
             style={{
              flexDirection: 'row', marginTop: 3,
              marginLeft: 20
            }}>
              <Layout style={{ flex: 7 }}><Text category='s2' appearance='hint'>{x.tenThanhPham}</Text></Layout>
              <Layout style={{ flex: 3 }}><Text category='s2' appearance='hint'>Số giờ: {x.soGioCong}</Text></Layout>
              <Layout style={{ flex: 2, alignItems: 'flex-end' }}>
                <CheckBox
                  checked={x.daDuyet}
                  onChange={value => onDaDuyetChange(value, x)}
                  disabled={false}
                  status='success'></CheckBox >
              </Layout>
            </Layout>
          })}
        </Layout>
      })}
    </Card>
  );

  return (
    <List
      {...listProps}
      contentContainerStyle={[styles.container, contentContainerStyle]}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingVertical: 4,
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 0,
  },
  itemDescription: {
    marginTop: 2,
  },
  secondaryText: {
  },
  layoutContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomSheetModal: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
  },
  bottomSheetModalItem: {
    flex: 1,
    width: '100%',
  },
  bottomSheetModalItemText: {
    textAlign: 'center'
  },
});
