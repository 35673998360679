import React, { useCallback, useMemo, useRef } from 'react';
import { ListRenderItemInfo, StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { Card, CardElement, Layout, List, ListElement, ListProps, Text } from '@ui-kitten/components';
import { LayoutItem } from '../model/layout-item.model';
import Animated, { Extrapolate, interpolate } from 'react-native-reanimated';

export interface LayoutListProps extends Omit<ListProps, 'renderItem'> {
  data: LayoutItem[];
  onItemPress: (index: number) => void;
  onItemLongPress?: (index: number) => void;
}

export type LayoutListElement = React.ReactElement<LayoutListProps>;

export const LayoutList = (props: LayoutListProps): ListElement => {

  const { contentContainerStyle, onItemPress, onItemLongPress, ...listProps } = props;

  const renderItem = (info: ListRenderItemInfo<LayoutItem>): CardElement => (
    <Card
      style={styles.itemContainer}
      onPress={() => onItemPress(info.index)}
      onLongPress={() => { onItemLongPress(info.index); }}>
      <Layout style={styles.layoutContainer}>
        <Layout style={{ ...styles.layout, flex: 5 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s1'>
            Số: RQ1235764
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s1'>
            Ngày: 12/08/2022
          </Text>
        </Layout>
      </Layout>
      <Layout style={styles.layoutContainer}>
        <Layout style={{ ...styles.layout, flex: 5 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s2' appearance='hint'>
            Công ty sdfsdfadsfasfa
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2' appearance='hint'>
            24550000
          </Text>
        </Layout>
      </Layout>
      <Layout style={styles.layoutContainer}>
        <Layout style={{ ...styles.layout, flex: 5 }} level='1'>
          <Text ellipsizeMode='tail' numberOfLines={1}
            category='s2' appearance='hint'>
            Đơn hàng Bood tháng 8
          </Text>
        </Layout>
        <Layout style={{ ...styles.layout, flex: 2, alignItems: 'flex-end' }} level='1'>
          <Text
            category='s2' appearance='hint' status='success'>
            Đã giao
          </Text>
        </Layout>
      </Layout>
    </Card>
  );

  return (
    <ScrollView style={{ height: Dimensions.get('window').height - 200 }}>
      <List
        {...listProps}
        contentContainerStyle={[styles.container, contentContainerStyle]}
        renderItem={renderItem}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingVertical: 4,
  },
  itemContainer: {
    marginVertical: 4,
    marginHorizontal: 0,
  },
  itemDescription: {
    marginTop: 2,
  },
  secondaryText: {
  },
  layoutContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  layout: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomSheetModal: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    flexDirection: 'column',
  },
  bottomSheetModalItem: {
    flex: 1,
    width: '100%',
  },
  bottomSheetModalItemText: {
    textAlign: 'center'
  },
});
