import { Alert, NativeModules } from 'react-native';
import { AppConst } from '../app/app-const';
import { AppStorage } from './app-storage.service';
import { authorize, AuthorizeResult, prefetchConfiguration, refresh, revoke } from 'react-native-app-auth';
import { AppRoute } from '../app/app-routes';
import { AppFetch } from './app-fetch.service';
import Toast from 'react-native-root-toast';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';

const config = {
  issuer: 'https://xbaccount.b24.vn',
  clientId: 'Xbook_pro_dong_tam_mobile_dev',
  redirectUrl: 'com.xbookdongtam.auth:/oauthredirect',
  scopes: ['openid', 'profile', 'offline_access'],
};
export class AppAuthService {
  static getConfig = () => []

  static handleAuthorize = async (navigation) => {
    try {
      const newAuthState = await authorize({
        ...config,
      });
      const result = {
        hasLoggedInOnce: true,
        provider: 'identityserver',
        ...newAuthState,
      };
      Toast.show('Đang lấy thông tin user');
      
      AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify(result));
      const userInfo = await AppFetch.get('https://xbaccount.b24.vn/connect/userinfo');
      AppStorage.set(AppConst.USER_INFO, JSON.stringify(userInfo));
      console.log(userInfo,'         1')
      const xbookUserInfo = await AppFetch.get('Account/GetUserInfo');
      AppStorage.set(AppConst.XBOOK_USER_INFO, JSON.stringify(xbookUserInfo));
    } catch (error) {
      Alert.alert('Failed to log in', error.message);
    }
  }
  static logout:() => Promise<any> = async () => {
    return AppAuthService.getIdToken().then(token => {
      return Linking.openURL(`https://xbaccount.b24.vn/connect/endsession?id_token_hint=${token}&post_logout_redirect_uri=${config.redirectUrl}`);
    })
  }

  static isLoggedIn:() => Promise<boolean> = async () => {
    const data = await AppStorage.get<AuthorizeResult>(AppConst.USER_IDENTITY_INFO);
    const token = data?.accessToken;
    return !!token;
  }

  static getToken: () => Promise<string> = async () => {
    const data = await AppStorage.get<AuthorizeResult>(AppConst.USER_IDENTITY_INFO);
    const token = data?.accessToken;
    return token;
  }
  static getIdToken: () => Promise<string> = async () => {
    const data = await AppStorage.get<AuthorizeResult>(AppConst.USER_IDENTITY_INFO);
    const token = data?.idToken;
    return token;
  }

  static getName: () => Promise<string> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const rs = data?.name;
    return rs;
  }

  static getEmail: () => Promise<string> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    const rs = data?.Email;
    return rs;
  }

  static getUserInfo: () => Promise<any> = async () => {
    const data = await AppStorage.get<any>(AppConst.USER_INFO);
    return data;
  }

  static getXbookUserInfo: () => Promise<any> = async () => {
    const data = await AppStorage.get<any>(AppConst.XBOOK_USER_INFO);
    return data;
  }
}
