import React, { ReactElement } from 'react';
import { Keyboard, Platform, StatusBar, TouchableOpacity, View } from 'react-native';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Datepicker,
  Divider,
  Icon,
  IndexPath,
  Input,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Tooltip,
  useStyleSheet,
} from '@ui-kitten/components';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { TouchableWithoutFeedback } from 'react-native';
import { ChamCongModelRequest } from '../../../model/cham-cong-item.model';
import { AppFetch } from '../../../services/app-fetch.service';
import { AppRoute } from '../../../app/app-routes';
import { AppAuth } from 'expo';
import { AppAuthService } from '../../../services/app-auth.service';
import Toast from 'react-native-root-toast';

const filter = (item, query) => item.toLowerCase().includes(query.toLowerCase());

const showEvent = Platform.select({
  android: 'keyboardDidShow',
  default: 'keyboardWillShow',
});

const hideEvent = Platform.select({
  android: 'keyboardDidHide',
  default: 'keyboardWillHide',
});

export default ({ navigation }): React.ReactElement => {

  const styles = useStyleSheet(themedStyles);

  const [sogioCong, setSogioCong] = React.useState<string>('8');
  const [maCongTrinh, setMaCongTrinh] = React.useState<string>();
  const [mathanhPham, setMathanhPham] = React.useState<string>();
  const [ngayCC, setNgayCC] = React.useState<Date>(new Date());
  const [tenNhanVien, setTenNhanVien] = React.useState<string>();
  const [maNhanVien, setMaNhanVien] = React.useState<string>();

  const [tenCongTrinh, setTenCongTrinh] = React.useState<string>();
  const [dataCongTrinh, setDataCongTrinh] = React.useState([]);
  const [tenThanhPham, setTenThanhPham] = React.useState<string>();
  const [dataThanhPham, setDataThanhPham] = React.useState([]);
  const [listCongTrinh, setListCongTrinh] = React.useState<any[]>([]);
  const [listThanhPham, setListThanhPham] = React.useState<any[]>([]);
  const [placement, setPlacement] = React.useState('bottom');
  React.useEffect(() => {
    AppFetch.get('project').then(rs => {
      setListCongTrinh(rs.data);
      setDataCongTrinh(rs.data);
    });
    AppFetch.get('product?categoryId=4').then(rs => {
      setListThanhPham(rs.data);
      setDataThanhPham(rs.data);
    });
    AppAuthService.getEmail().then(email => {
      setMaNhanVien(email);
    });
    AppAuthService.getName().then(name => {
      setTenNhanVien(name);
    });
  }, []);
  React.useCallback(() => {
  }, [listCongTrinh]);

  React.useEffect(() => {
    const keyboardShowListener = Keyboard.addListener(showEvent, () => {
      setPlacement('top');
    });

    const keyboardHideListener = Keyboard.addListener(hideEvent, () => {
      setPlacement('bottom');
    });

    return () => {
      keyboardShowListener.remove();
      keyboardHideListener.remove();
    };
  });
  Toast.show(StatusBar?.currentHeight?.toString());

  const onAddButtonPress = (): void => {
    console.log(ngayCC.toJSON())
    const date = ngayCC;
    // date.setDate(date.getDate() + 1); 
    const model: ChamCongModelRequest = {
      id: 0,
      daDuyet: false,
      maCongTrinh: listCongTrinh.find(item => item.projectName === tenCongTrinh)?.projectCode,
      maNhanVien,
      maThanhPham: listThanhPham.find(item => item.productName === tenThanhPham)?.productCode,
      tenThanhPham: tenThanhPham,
      ngayCC: date,
      soGioCong: Number(sogioCong),
      tenNhanVien
    };
    if (!model.maCongTrinh) {
      return;
    }
    AppFetch.post('ChamCongXuong', model).then(rs => {
      navigation && navigation.navigate(AppRoute.CHAM_CONG_LIST);
    });
  };
  const onChangeSoGioCong = (text) => {
    setSogioCong(text.replace(/[^.(0-9)]/gi, ''));
  }

  return (
    <KeyboardAvoidingView style={styles.container}>
      <Layout
        style={styles.form}
        level='1'>
        <Autocomplete
          style={styles.input}
          label='Công trình'
          placeholder='Chọn công trình'
          value={tenCongTrinh}
          placement={placement}
          onSelect={(index) => { setTenCongTrinh(dataCongTrinh[index].projectName) }}
          onChangeText={(query) => {
            setTenCongTrinh(query);
            setDataCongTrinh(listCongTrinh.filter(item => filter(item.projectName, query)).slice(0, 5));
          }}
        >
          {dataCongTrinh.map(item => <AutocompleteItem title={item?.projectName} key={item?.projectCode} />)}
        </Autocomplete>
        <Autocomplete
          style={styles.input}
          label='Thành phẩm'
          value={tenThanhPham}
          placeholder='Chọn thành phẩm'
          placement={placement}
          onSelect={(index) => { setTenThanhPham(dataThanhPham[index].productName) }}
          onChangeText={(query) => {
            setTenThanhPham(query);
            setDataThanhPham(listThanhPham.filter(item => filter(item.productName, query) || filter(item.productCode, query)).slice(0, 5));
          }}
        >
          {dataThanhPham.map(item => <SelectItem title={item?.productName} key={item?.productId} />)}
        </Autocomplete>

        <View style={styles.middleContainer}>
          <Datepicker
            style={[styles.input, styles.middleInput]}
            label='Ngày'
            date={ngayCC}
            onSelect={date => { date.setUTCHours(24, 0, 0, 0); console.log(date); setNgayCC(date) }}
            min={new Date(1900, 0, 0)}
            max={new Date(2050, 0, 0)}
          />
          <Input
            style={[styles.input, styles.middleInput]}
            label='Số giờ'
            placeholder='Nhập số giờ'
            maxLength={4}
            value={sogioCong}
            onChangeText={onChangeSoGioCong}
          />
        </View>
      </Layout>
      <Divider />
      <Button
        style={styles.addButton}
        size='giant'
        onPress={onAddButtonPress}>
        Lưu
      </Button>
    </KeyboardAvoidingView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
  },
  form: {
    flex: 1,
    paddingHorizontal: 4,
    paddingVertical: 24,
  },
  input: {
    marginHorizontal: 12,
    marginVertical: 8,
  },
  middleContainer: {
    flexDirection: 'row',
  },
  middleInput: {
    flex: 1,
  },
  addButton: {
    marginHorizontal: 16,
    marginVertical: 24,
  },
});
