import { Button, Divider, Layout, ModalService, Text } from '@ui-kitten/components';
import React, { useCallback, useMemo, useRef } from 'react';
import { Pressable, View } from 'react-native';
import { AppFetch } from '../../../services/app-fetch.service';
import { LayoutList, LayoutListElement } from '../../../components/layout-list.component';
import { data } from './extra/data';
import { useRoute } from '@react-navigation/native';
import { SaleInvoiceViewModel } from '../../../model/sale-invoice.model';
import { StatusLabel } from '../sale-invoice-list/extra/status-label.component';
import moment from 'moment';
import { AppAuthService } from '../../../services/app-auth.service';
import Toast from 'react-native-root-toast';

export default ({ navigation }): LayoutListElement => {
  const [model, setModel] = React.useState<SaleInvoiceViewModel>(null);
  const route: any = useRoute();
  const { invoiceId } = route.params;
  const subTotal = model?.saleInvDetailView.map(x => x.amount).reduce((item, count) => item + count, 0);
  const soTienChuongTrinh = subTotal * model?.phanTramChuongTrinh / 100;
  const discount = subTotal * model?.discRate / 100;
  const loadData = () => {
    AppFetch.post(`SaleInvoice/GetSaleInvoiceById/${invoiceId}`, {}).then(rp => {
      const data = rp[0];
      if (rp[0]?.priceTableCampaignClientId) {
        AppFetch.get(`PriceTableCampaignClient/${rp[0]?.priceTableCampaignClientId}`).then(rs => {
          data.chuongTrinh = rs?.data?.name;
          setModel(data);
        });
      } else {
        setModel(data);
      }
    });
  };
  React.useEffect(() => {
    loadData()
  }, []);

  //#region Modal

  let modalID = '';
  const onLongPress = (): void => {
    // navigation.navigate(data[index].route);
    const contentElement = renderModalContentElement();
    modalID = ModalService.show(contentElement, { onBackdropPress: hideModal, backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } });
  };

  const hideModal = () => {
    ModalService.hide(modalID);
  };
  const renderModalContentElement = () => {
    return (
      <Pressable onPress={hideModal} style={{ flex: 1, justifyContent: 'flex-end' }}>
        <Layout style={{ flex: 1, justifyContent: 'flex-end', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <Button
            onPress={() => onPressTrangThai(0)}
            appearance='outline'
            status='warning'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Chưa Duyệt
          </Button>
          <Button
            onPress={() => onPressTrangThai(1)}
            appearance='outline'
            status='success'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Đã Duyệt
          </Button>
          <Button
            onPress={() => onPressTrangThai(2)}
            appearance='outline'
            status='primary'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Đã Xuất Kho
          </Button>
          <Button
            onPress={() => onPressTrangThai(3)}
            appearance='outline'
            status='info'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Đã Giao Hàng
          </Button>
          <Button
            onPress={() => onPressTrangThai(4)}
            appearance='outline'
            status='basic'
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}>
            Huỷ
          </Button>
        </Layout>
      </Pressable>
    );
  };

  const onPressTrangThai = async (trangThai) => {
    hideModal();
    const item = model;
    /**
         * Set Data
         */
    const request = [{ invoiceId: item.invoiceId, invoiceStatus: trangThai }];
    /**
     * Validate
     */
    if (trangThai > item.trangThai + 1 || trangThai < item.trangThai - 1) {
      Toast.show('Các hóa đơn phải đổi qua trạng thái kế tiếp');
      return;
    }
    const permissions = (await AppAuthService.getXbookUserInfo())?.permission;
    if (!permissions.some(x => x.functionId === 'Invoice' && x.update === true)) {
      if (trangThai === 0 && trangThai < item.trangThai) {
        Toast.show('Chỉ người kiểm duyệt mới được chuyển về "chưa duyệt"');
        return;
      }
      if (trangThai === 1 && trangThai > item.trangThai) {
        Toast.show('Chỉ người kiểm duyệt mới được duyệt đơn');
        return;
      }
    }

    /**
     * Request
     */
    AppFetch.patch(`SaleInvoice/UpdateInvoiceStatus`, request).then(rp => {
      Toast.show('Đã đổi trạng thái');
      loadData();
    });
  }
  //#endregion

  return (
    <Layout level='3' style={{ flex: 1, marginBottom: 10 }}>
      <Layout level='3' style={{ height: 35, flexDirection: 'row', marginHorizontal: 20, marginTop: 20 }}>
        <Text category='h5'>Đơn hàng {model?.invoiceNumber}</Text>
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
          <Pressable onLongPress={onLongPress}>
            <StatusLabel status={model?.trangThai} />
          </Pressable>
        </View>
      </Layout>
      <Layout level='3' style={{ height: 35, flexDirection: 'row', marginHorizontal: 20, marginTop: 20 }}>
        <Text category='s2' style={{ flex: 4 }}>{model?.clientData[0]?.clientName}</Text>
        <Text category='s2' style={{ flex: 2 }}>CT: {model?.chuongTrinh}</Text>
        <Text category='s2' style={{ flex: 1, textAlign: 'right' }}>
          {moment(model?.issueDate).format('DD/MM/YYYY')}
        </Text>
      </Layout>
      <View style={{ flex: 1, borderRadius: 10, overflow: 'hidden', marginHorizontal: 20 }}>
        <Layout style={{ flex: 1, padding: 10, height: 30 }}>
          <View>
            <Layout style={{ flexDirection: 'row', marginBottom: 10 }}>
              <Text category='s2' style={{ flex: 3 }}>Tên hàng hoá(Mã)</Text>
              <Text category='s2' style={{ flex: 1 }}>ĐG</Text>
              <Text category='s2' style={{ flex: 1, textAlign: 'center' }}>SL</Text>
              <Text category='s2' style={{ flex: 1, textAlign: 'right' }} numberOfLines={1}>Thành tiền</Text>
            </Layout>
            <Divider />
          </View>
          {model?.saleInvDetailView.map(item => (
            <View>
              <Layout style={{ flexDirection: 'row', minHeight: 30 }}>
                <Text category='p2' style={{ flex: 3 }} numberOfLines={2}>
                  ({item.description.split(')')[0].split('(').reverse()[0]})
                 {item.productName}
                </Text>
                <Text category='p2' style={{ flex: 1 }}>{item.price.toLocaleString('en-US')}</Text>
                <Text category='p2' style={{ flex: 1, textAlign: 'center' }}>{item.qty.toLocaleString('en-US')}</Text>
                <Text category='p2' style={{ flex: 1, textAlign: 'right' }}>{item.amount.toLocaleString('en-US')}</Text>
              </Layout>
              <Divider />
            </View>
          ))}
        </Layout>
      </View>
      <Layout level='3' style={{ height: 35, marginHorizontal: 20, padding: 10, flexDirection: 'row' }}>
        <Text category='s1' style={{ flex: 3 }}>Doanh số</Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>
        </Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'right' }}>
          {subTotal?.toLocaleString('en-US')}
        </Text>
      </Layout>
      <Layout level='3' style={{ height: 35, marginHorizontal: 20, padding: 10, flexDirection: 'row' }}>
        <Text category='s1' style={{ flex: 3 }}>Chiết khấu</Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>
          {model?.discRate}%
        </Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'right' }}>
          {discount?.toLocaleString('en-US')}
        </Text>
      </Layout>
      <Layout level='3' style={{ height: 35, marginHorizontal: 20, padding: 10, flexDirection: 'row' }}>
        <Text category='s1' style={{ flex: 3 }}>Chương Trình</Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>
          {model?.phanTramChuongTrinh?.toLocaleString('en-US')}%
        </Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'right' }}>
          {soTienChuongTrinh?.toLocaleString('en-US')}
        </Text>
      </Layout>
      <Layout level='3' style={{ height: 35, marginHorizontal: 20, padding: 10, flexDirection: 'row' }}>
        <Text category='s1' style={{ flex: 3 }}>Doanh số sau CK</Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'center' }}>
        </Text>
        <Text category='s1' style={{ flex: 1, textAlign: 'right' }}>
          {(subTotal + soTienChuongTrinh - discount).toLocaleString('en-US')}
        </Text>
      </Layout>
    </Layout>
  );
};
