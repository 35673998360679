import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { View, TouchableWithoutFeedback, Platform, Alert } from 'react-native';
import { Button, Input, Layout, StyleService, Text, useStyleSheet, Icon } from '@ui-kitten/components';
import { PersonIcon } from './extra/icons';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { AppRoute } from '../../../app/app-routes';
import * as WebBrowser from 'expo-web-browser';
import * as SecureStore from 'expo-secure-store';
import Constants from 'expo-constants';
import { authorize, AuthorizeResult, prefetchConfiguration, refresh, revoke } from 'react-native-app-auth';
import { AppConst } from '../../../app/app-const';
import { AppStorage } from '../../../services/app-storage.service';
import { AppAuthService } from '../../../services/app-auth.service';
import * as AuthSession from 'expo-auth-session';
import { CommonActions } from '@react-navigation/native';

// Note "offline_access" scope is required to get a refresh token
const config = {
  issuer: 'https://xbaccount.b24.vn',
  clientId: 'Xbook_pro_dong_tam_mobile_dev',
  redirectUrl: 'com.xbookdongtam.auth:/oauthredirect',
  scopes: ['openid', 'profile', 'offline_access'],
};

const defaultAuthState = {
  hasLoggedInOnce: false,
  provider: '',
  accessToken: '',
  accessTokenExpirationDate: '',
  refreshToken: ''
};
WebBrowser.maybeCompleteAuthSession();
export default ({ navigation }): React.ReactElement => {
  //#region Auth

  const [authState, setAuthState] = useState(defaultAuthState);
  React.useEffect(() => {
    prefetchConfiguration({
      warmAndPrefetchChrome: true,
      ...config,
    });
  }, []);

  // const handleAuthorize = useCallback(
  //   async () => {
  //     await AppAuthService.handleAuthorize(navigation, {});
  // try {
  //   const newAuthState = await authorize({
  //     ...config,
  //   });
  //   const result = {
  //     hasLoggedInOnce: true,
  //     provider: 'identityserver',
  //     ...newAuthState,
  //   };

  //   setAuthState(result);
  //   AppStorage.set(AppConst.USER_IDENTITY_INFO, JSON.stringify(result));

  //   navigation && navigation.navigate(AppRoute.HOME);
  // } catch (error) {
  //   Alert.alert('Failed to log in', error.message);
  // }
  // },
  //   [authState],
  // );

  // const handleRefresh = useCallback(async () => {
  //   try {
  //     const newAuthState = await refresh(config, {
  //       refreshToken: authState.refreshToken,
  //     });

  //     setAuthState(current => ({
  //       ...current,
  //       ...newAuthState,
  //       refreshToken: newAuthState.refreshToken || current.refreshToken,
  //     }));
  //   } catch (error) {
  //     Alert.alert('Failed to refresh token', error.message);
  //   }
  // }, [authState]);

  // const handleRevoke = useCallback(async () => {
  //   try {
  //     await revoke(config, {
  //       tokenToRevoke: authState.accessToken,
  //       sendClientId: true,
  //     });

  //     setAuthState(defaultAuthState);
  //   } catch (error) {
  //     Alert.alert('Failed to revoke token', error.message);
  //   }
  // }, [authState]);

  // const showRevoke = useMemo(() => {
  //   if (authState.accessToken) {
  //     if (config.issuer || config.serviceConfiguration.revocationEndpoint) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }, [authState]);

  //#endregion

  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

  const styles = useStyleSheet(themedStyles);

  const onSignUpButtonPress = (): void => {
    navigation && navigation.navigate('SignUp2');
  };

  let authRequest = {};

  if (Platform.OS == 'web') {
    const [requestInfo, issuer] = AppAuthService.getConfig();
    const discovery = AuthSession.useAutoDiscovery(issuer);
    // Create and load an auth request
    authRequest = AuthSession.useAuthRequest(
      requestInfo,
      discovery
    );
  }
  
  const onSignInButtonPress = (): void => {
    AppAuthService.handleAuthorize(navigation, authRequest).then(() => {
      if (AppAuthService.isLoggedIn()) {
        navigation.dispatch(
          CommonActions.reset({
            index: 1, // the stack index
            routes: [
              { name: 'pages' }, // to go to initial stack screen
            ],
          })
        );
        navigation && navigation.navigate(AppRoute.ROOT, { screen: AppRoute.HOME });
      }
     });
  };

  const onForgotPasswordButtonPress = (): void => {
    navigation && navigation.navigate('ForgotPassword');
  };

  const onPasswordIconPress = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  const renderPasswordIcon = (props): ReactElement => (
    <TouchableWithoutFeedback onPress={onPasswordIconPress}>
      <Icon {...props} name={passwordVisible ? 'eye-off' : 'eye'} />
    </TouchableWithoutFeedback>
  );
  return (
    <KeyboardAvoidingView style={styles.container}>
      <View style={styles.headerContainer}>
        <Text
          category='h1'
          status='control'>
          Xin chào
        </Text>
        <Text
          style={styles.signInLabel}
          category='s1'
          status='control'>
          Chào mừng đến với Xbook
        </Text>
      </View>
      <Layout
        style={styles.formContainer}
        level='1'>
        {/* <Input
          placeholder='Email'
          accessoryRight={PersonIcon}
          value={email}
          onChangeText={setEmail}
        />
        <Input
          style={styles.passwordInput}
          placeholder='Password'
          accessoryRight={renderPasswordIcon}
          value={password}
          secureTextEntry={!passwordVisible}
          onChangeText={setPassword}
        />
        <View style={styles.forgotPasswordContainer}>
          <Button
            style={styles.forgotPasswordButton}
            appearance='ghost'
            status='basic'
            onPress={onForgotPasswordButtonPress}>
            Forgot your password?
          </Button>
        </View> */}
      </Layout>
      <Button
        style={styles.signInButton}
        size='giant'
        onPress={onSignInButtonPress}>
        ĐĂNG NHẬP
      </Button>
      {/* <Button
        style={styles.signUpButton}
        appearance='ghost'
        status='basic'
        onPress={onSignUpButtonPress}>
        Don't have an account? Create
      </Button> */}
    </KeyboardAvoidingView>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background-basic-color-1',
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
    backgroundColor: 'color-primary-default',
  },
  formContainer: {
    flex: 1,
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  signInLabel: {
    marginTop: 16,
  },
  signInButton: {
    marginHorizontal: 16,
    marginBottom: 50,
  },
  signUpButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  forgotPasswordContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  passwordInput: {
    marginTop: 16,
  },
  forgotPasswordButton: {
    paddingHorizontal: 0,
  },
});

